export const translation_lv = {
	main: 'Sākumlapa',
	search_tours: 'Meklēt ceļojumu',
	contacts: 'Kontakti',
	blog: 'Blogs',
	get_offer: 'Saņemt piedāvājumu',
	//main-form
	from_to: 'Izlidošanas pilsēta',
	direction: 'Galamērķis',
	choose_from_to: 'Izvēlieties izlidošas pilsētu',
	choose_direction: 'Izvēlieties galasmērķi',
	departure: 'Izlidošana',
	date: 'Datums',
	fill_in_the_field: 'Aizpildiet laukus',
	nights: 'Ilgums',
	nights_register: 'naktis',
	flight_info: 'par lidojumu',
	flight_info_full: 'Informācija par lidojumu',
	flight: 'Lidojums',
	not_have_data: 'Nav informācijas',
	country_visit: 'Izlidošana turp',
	country_from: 'Izlidošana atpakaļ',
	last_name: 'Uzvārds',
	guests: 'Viesi',
	search: 'Meklēt',
	searchFilter: 'Piemērot',
	meal: 'Ēdināšana',
	//Main_Page
	get_travel_dream:
		'Izvēlieties labāko piedāvājumu starp lielākajām ceļojumu aģentūrām',
	fs_main_description:
		'Mēs piedāvājam labāko cenu un papildus 5% atlaidi ar promo kodu',
	fs_main_promocode: 'ATPUTA2025',
	//card-tour
	best_tour: 'Labākie piedāvājumi',
	offer_mb_interest: 'Mūsu piedāvājumi, kas varētu jūs ieinteresēt',
	best_tour_description: 'Cenas un kvalitātes attiecība',
	popular_tour: 'Populāri piedāvājumi',
	mb_get_offer: 'Vēlaties saņemt individuālo piedāvājumu?',
	we_find_variant: 'Mēs jums atradīsim labāko variantu',
	get: 'Saņemt',
	principle_operation: 'Darbības princips',
	mandarina_app_has_many_advantages:
		'Mandarina aplikācijai ir vairākas priekšrocības',
	compliance_with_deadlines: 'Termiņu ievērošana',
	compliance_with_deadlines_desc:
		'Savlaicīgi un bez kavēšanās veicam visas nepieciešamās darbības jūsu ceļojumam. Ceļojumu piedāvājumus sagatavojam īsā laika periodā.',
	quality_assurance: 'Kvalitātes nodrošināšana',
	quality_assurance_desc:
		'Sadarbojamies tikai ar uzticamiem tūrisma operatoriem un nodrošinām augsta līmeņa servisu.',
	favorable_prices: 'Izdevīgas cenas',
	favorable_prices_desc:
		'Piedāvājam plašu ceļojumu klāstu ar iespēju salīdzināt cenas un izvēlēties sev piemērotāko un izdevīgāko variantu.',
	feedback_from_our_customers: 'Mūsu klientu atsauksmes',
	we_glad_feedback:
		'Esam priecīgi, ka lielākā daļa mūsu klientu ir cilvēki, ar kuriem mums ir izveidojušās pastāvīgas un uzticamas attiecības.',
	feedback_from_our_customers_1:
		'Sen gribēja lidot atpūsties izvēle uzreiz krita uz Mandarin 👌 palika ļoti apmierināti, mēs ātri paņēma iespējas mūsu pieprasījumu un milzīgs plus, ka ir akcija agri rezervāciju + jūs varat rezervēt attālināti. Gaidām mūsu braucienu ❤️',
	feedback_from_our_customers_2:
		'laba atpūta! paldies! palika ļoti apmierināti!',
	feedback_from_our_customers_3:
		'Ļoti foršs atpūtās Grieķijā. Atbildiet nekavējoties, cenas ir patīkamas!',
	feedback_from_our_customers_4:
		'Es ieteiktu šo uzņēmumu - ļoti ātri atbildēt!',
	feedback_from_our_customers_5:
		'Viss ir labi organizēts un palīdz no šī uzņēmuma līdz galam.',
	feedback_from_our_customers_person_1: 'Iveta Babenko',
	feedback_from_our_customers_person_2: 'Kristīna Rodeviča',
	feedback_from_our_customers_person_3: 'Darja Kuzņecova',
	feedback_from_our_customers_person_4: 'Andrejs Bogomolovs',
	feedback_from_our_customers_person_5: 'Andrew Andrew',
	tips_for_your_vacation_from_travel_experts:
		'Ceļojumu ekspertu ieteikumi Tavai atpūtai!',
	get_inspired_for_your_next_trip: 'Smelies iedvesmu nākamajam ceļojumam.',
	looks: 'Apskatīt',
	open_all_articles: 'Atveriet visus rakstus',
	popular_destionations: 'Populāri galamērķi',
	subtitleGR: 'Korfu, Krēta, Rodas sala',
	subtitleEG: 'Hurgada, Šarm El Šeiha',
	subtitleCN: 'Tenerife',
	subtitleTR: 'Antālija, Alanija, Beleka, Kemera, Side',
	subtitleME: 'Tivata',
	these_are_popular_destinations_over_the_past_month:
		'Kur doties atvaļinājumā 2024. gadā',
	over_14_years_old: 'Vecāks par 14 gadiem',
	and_better: 'un labāk',
	number_of_years: 'vecums',
	from_2_to_14_years_old: 'Līdz 14 gadu vecumam',
	select_options: 'izvelieties opcijas',
	//meals
	without_power: 'Bez ēdināšanas',
	breakfast: 'Brokastis',
	half_board: 'Puspansija',
	full_board: 'Pilna pansija',
	all_inclusive: 'Viss iekļauts',
	all_inclusive_plus: 'Viss iekļauts+',
	//country
	Bulgaria: 'Bulgārija',
	Italy: 'Itālija',
	CanaryIslands: 'Kanāriju Salas',
	Portugal: 'Portugāle',
	Georgia: 'Gruzija',
	Cyprus: 'Kipra',
	Tunisia: 'Tunisija',
	Thailand: 'Taizeme',
	Mexico: 'Meksika',
	spain: 'Spānija',
	egypt: 'Ēģipte',
	turkey: 'Turcija',
	greece: 'Grieķija',
	hotels: 'viesnīcas',
	dominican_republic: 'Dominikānas Republika',
	/////Mailgail_block
	get_news_to_your_email: 'Saņem jaunumus savā e-pastā',
	great_suggestions_ideas_and_tips_for_a_successful_trip:
		'Izdevīgi piedāvājumi, idejas un padomi ceļojumam',
	your_email: 'Tavs e-pasts',
	subscribe: 'Pieteikties',
	//footer
	additionally: 'Papildus informācija',
	Menu: 'Izvēlne',
	refund_policy: 'Atgriešanas nosacījumi',
	security_policy: 'Privātuma politika',
	cookie_policy: 'Sīkdatņu izmantošanas politika',
	terms_of_service: 'Pakalpojuma noteikumi',
	all_rights_reserved: 'Visas tiesības aizsargātas',
	social_mes: 'Sociālie tīkli',
	a_service_for_quick_and_easy_registration_of_travel_anywhere_in_the_world:
		'Serviss ātrai un vienkāršai ceļojumu noformēšanai uz jebkuru vietu pasaulē',
	//search-tours
	travel_search: 'Meklēšana pēc ceļojuma',
	title_first_screen_get_offer:
		'Aizpildi formu un saņem individuālo piedāvājumu',
	travel_offers: 'ceļojuma piedāvājumi',
	which_hotel_did_you_choose: 'Kādu viesnīcu izvēlējāties?',
	Uploading_offers: 'Meklējam piedāvājumus...',
	price_range: 'Cenu diapazons',
	city: 'Pilsēta',
	number_of_nights_reduction: 'Nakšu skaits',
	hotel_rating: 'Viesnīcas reitings',
	hotel_location: 'Atrašanās',
	hotel_comfort: 'Ērtība',
	for_kids: 'Bērniem',
	for_active_rest: 'Atpūta',
	type_of_food: 'Ēdināšanas veids',
	reset: 'Nodzēst',
	sort: 'Kārtot pēc',
	//sort
	very_popular: 'Populārākie',
	min_max: 'Minimālā cena - maksimālā',
	max_min: 'Maksimālā cena - minimālā',
	rating_min_max: 'Vērtējums 1-5',
	rating_max_min: 'Vērtējums 5-1',
	ta_rating: 'TripAdvisor vērtējums',
	google_rating: 'Google vērtējums',
	//
	filter: 'Filtrēt',
	delete: 'Dzēst',
	the_duration_of_the_trip_from: 'Ceļojuma ilgums no:',
	the_price_includes: 'Cenā iekļauts:',
	the_price_depends_on_the_date_of_departure_and_the_type_of_food:
		'*Cena ir atkarīga no izlidošanas datuma un ēdināšanas veida',
	//hotel
	at_all: 'visiem',
	c: 'no',
	No_tours_found: 'Ekskursijas nav atrastas',
	Select_date: 'Izvēlieties datumu un pārbaudiet pieejamību',
	price_up_to: 'Cena līdz:',
	number_of_nights: 'Nakšu skaits',
	check_availability: 'Pārbaudiet pieejamību',
	departure_date: 'Izlidošanas datums',
	number_of_seats_on_the_plane: 'Vietas lidmašīnā',
	room_type: 'Numura tips',
	choose_date: 'Izvēlieties datumu',
	price_for_all: 'Cena visiem',
	search_for_suitable_offers: 'Meklējam labākos piedāvājumus...',
	info_about_hotels: 'Par viesnīcu',
	services_for_children: 'Pakalpojumi Bērniem',
	activities: 'Aktivitāte',
	note: 'Piezīme',
	not_a_info: 'Pagaidām informācijas nav',
	location: 'Atrašanās',
	loading: 'Meklējam piedāvājumus',
	not_a_found: 'Pēc izvēlētajiem parametriem nav rezultātu ',
	not_info_about_hotel: 'Pēc izvēlētajiem parametriem nav rezultātu',
	Use_the_search_to_get_offers:
		'Izmantojiet meklētāju lai saņemtu piedāvājumu',
	order: 'Pasūtīt',
	//get-offer
	when_do_you_want_to_go_on_a_trip: 'Kad vēlies doties ceļojumā?',
	i_know_the_exact_dates: 'Es zinu precīzus datumus',
	next_step: 'Nākamais solis',
	where_do_you_want_to_go: 'Uz kurieni vēlies doties?',
	select_the_number_of_people: 'Izvēlieties cilvēku skaitu',
	adults: 'Pieaugušie',
	adult_checout: 'pieaugušajiem',
	adults_checout: 'pieaugušajiem',
	childs_checout: 'bērns',
	Promo_code_discount: 'Atlaide ar promokodu',
	placeholder_promocode: 'Ievadiet promokodu',
	childs: 'Bērni',
	babyes: 'Mazuļi',
	select_a_price_range: 'Izvēlieties cenu diapazonu',
	we_will_try_to_find_the_best_offers_for_you:
		'Mēs centīsimies jums atrast vislabākos piedāvājumus',
	suggestions_and_comments: 'Novēlējumi un komentāri',
	if_you_have_any_additional_requests_or_comments_in_connection_with_the_trip_write_to_us:
		'Ja jums ir papildus vēlmes vai komentāri saistībā ar braucienu, lūdzu, uzrakstiet tos',
	hotel_room_type_catering_other: 'Viesnīca, numura tips, ēdināšana, citi',
	leave_your_contact_information_so_that_we_can_contact_you:
		'Ievadiet savus kontaktus, lai varam ar jums sazināties',
	firstname: 'Vārds',
	lastname: 'Uzvārds',
	phone: 'Tālruņa numurs',
	email: 'E-pasts',
	Uzdiviem: 'Uz diviem',
	enter_your_name_here: 'Ievadiet savu vārdu šeit',
	enter_your_last_name_here: 'Ievadiet savu uzvārdu šeit',
	enter_your_phone_number_here: 'Ievadiet savu tālruņa numuru šeit',
	enter_your_email_address_here: 'Šeit ievadiet savu e-pasta adresi',
	back: 'Atpakaļ',
	this_field_is_required: 'Šis lauks ir obligāts',
	//month
	may: 'Maijs',
	may_pad: 'Maijs',
	june: 'Jūnijs',
	june_pad: 'Jūnijs',
	july: 'Jūlijs',
	july_pad: 'Jūlijs',
	august: 'Augusts',
	august_pad: 'Augusts',
	september: 'Septembris',
	september_pad: 'Septembris',
	october: 'Oktobris',
	october_pad: 'Oktobris',
	november: 'Novembris',
	november_pad: 'Novembris',
	december: 'Decembris',
	december_pad: 'Decembris',
	january: 'Janvāris',
	january_pad: 'Janvāris',
	february: 'Februāris',
	february_pad: 'Februāris',
	mart: 'Marts',
	mart_pad: 'Marts',
	april: 'Aprīlis',
	april_pad: 'Aprīlis',
	//FromTo
	riga: 'Rīga',
	talin: 'Tallina',
	vilnus: 'Viļņa',
	montenegro: 'Melnkalne',
	//checkout:'',
	filling_in_the_data: 'Datu aizpildīšana',
	please_fill_in_your_details: 'Lūdzu, aizpildiet savus datus',
	booking_information: 'Informācija par rezervāciju',
	change: 'Mainīt',
	hotel: 'Viesnīca',
	the_price_includes_list:
		'lidojums ar bagāžu, viesnīca, izvēlētais ēdināšanas tips, pārstāvja pakalpojumi un grupas transfers',
	type_number: 'Numura tips',
	contact_information: 'Ceļotājs',
	phone_number: 'Tālruņa numurs',
	adres_email: 'Epasts',
	date_of_birth: 'Dzimšanas datums',
	price_details: 'Cenas detaļas',
	price_for: 'Cena par',
	final_price: 'Kopējā summa',
	lietot: 'Aktivizēt',
	prepayment: 'Apmaksai tagad',
	payment_after: 'Apmaksai pēc tam',
	method_pay: 'Maksājuma veids',
	payment_by_card_or_internet_bank: 'Maksāt ar karti (KLIX)',
	receive_an_invoice_by_email: 'Saņemt rēķinu uz epastu',
	i_agree_with: 'Es piekrītu ',
	and: ' un ',
	terms_of_service_v2: 'pakalpojuma noteikumiem',
	refund_rules_v2: 'atgriešanas nosacījumiem',
	go_to_pay: 'Pāriet uz maksājumu',
	///
	two_adults: 'Divi pieaugušie',
	not_have_offers: 'Nav tuvāko piedāvājumu',
	hotels_v2: 'piedāvājumi',
	nights_reister: 'naktis',
	///contacts
	ocntact_of_company: 'Mūsu kontakti',
	contact_info_contact_pay: 'Kontaktinformācija un rekvizīti',
	call_us_contact: 'Sazinieties ar mums',
	our_request: 'Jūsu pieprasījums',
	our_req_text:
		'Šeit varat uzdot jautājumus vai aprakstīt situāciju, ar kuru saskaraties',
	send: 'Atsūtīt',
	reqvisit: 'Rekvizīti',
	jar_addres: 'Juridiskā adrese',
	reg_number: 'Reģistrācijas numurs',
	license: 'licence',
	last_asking: 'Vai jums ir kādi jautājumi?',
	call_us_and: 'Sazinaties ar mums jums ērtā veidā.',
	sale_departament: 'Pārdošanas nodaļa',
	ask_for_sale_departament: 'Jautājumiem par sadarbību',
	help_departament: 'Palīdzības nodaļa',
	ask_for_help_departament: 'Ceļojumu jautājumiem',
	////payment-successful
	thank_you_for_order: 'Paldies par pasūtījumu!',
	thank_you_for_order_text:
		'Visa informācija par rezervāciju tiks nosūtīta uz norādīto e-pasta adresi.',
	back_to_home_button: 'Atpakaļ uz sākumlapu',
	kontakti: 'Kontakti',
	not_found: 'Lapa nav atrasta',
	subscribe_text: 'Jūs esat veiksmīgi reģistrējies',
	address_text: 'Paldies par jūsu attieksmi, mēs ar Jums sazināsimies!',
	rate_cleanliness: 'Tīrība',
	rate_room: 'Numurs',
	rate_value: 'Likme',
	rate_service: 'Pakalpojums',
	rate_sleep: 'Miega kvalitāte',
	rate_location: 'Atrašanās',
	read_all_review: 'Lasīt visas atsauksmes',
	guests_mark: 'Viesi atzīmēja:',
	hotelReviews_reviews: 'Atsauksmes:',
	The_average_rating_reviews_Google_Tripadvisor:
		'Vidējais viesnīcu vērtējums pēc apmeklētāju atsauksmēm no Google un Tripadvisor',
	atsauksmes: 'atsauksmes',
	Unwrap: 'Izvērst',
	All_reviews: 'Visas atsauksmes',
	error_fromTownCode: 'Izvēlieties izlidošanas pilsētu',
	error_countryCode: 'Izvēlieties valsti',
	error_date: 'Izvēlieties datumu',
	error_meal_types: 'Izvēlieties barošanas veidu',
	formInfoError_date: 'Ievadiet dzimšanas datumu',
	formInfoError_email: 'Ievadiet pastu',
	formInfoError_firstName: 'Ievadiet vārdu',
	formInfoError_lastName: 'Ievadiet uzvārdu',
	formInfoError_phone: 'Ievadiet tālruņa numuru',
	formInfoError_payment: 'Izvēlieties maksājuma metodi',

	// meal types
	UAI: 'Viss iekļauts+',
	ALACARTEAI: 'Viss iekļauts (a la carte)',
	AI: 'Viss iekļauts',
	FB: 'Pilna pansija',
	HB: 'Puspansija',
	RO: 'Tikai numurā',
	BB: 'Brokastis',
	SC: 'Bez barošanas',

	paymentFailure_for_order: 'Maksājums neizdevās',
	paymentFailure_for_order_text: 'Mēģiniet vēlreiz vai sazinieties ar mums.',
	day: 'dienas',
	beach: 'Pludmale'
}
